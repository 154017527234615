import type React from 'react';
import '@onefootprint/ui/styles.css';
import { AppearanceProvider } from '@onefootprint/appearance';
import { FootprintProvider, configureFootprint } from '@onefootprint/idv';
import { QueryClientProvider } from '@tanstack/react-query';
import type { AppProps } from 'next/app';

import configureI18n from '../../config/initializers/i18next';
import queryClient from '../../config/initializers/react-query';
import { BifrostMachineProvider } from '../bifrost-machine-provider';

const footprint = configureFootprint();

const App = ({
  children,
  pageProps,
}: {
  children: React.ReactNode;
  pageProps: AppProps['pageProps'];
}) => {
  const { appearance, theme, rules, language } = pageProps;
  configureI18n({ language: language ?? 'en' });

  return (
    <QueryClientProvider client={queryClient}>
      <AppearanceProvider appearance={appearance} theme={theme} rules={rules}>
        <BifrostMachineProvider>
          <FootprintProvider client={footprint}>{children}</FootprintProvider>
        </BifrostMachineProvider>
      </AppearanceProvider>
    </QueryClientProvider>
  );
};

export default App;
